<template>
  <div
    v-show="datatotal > 0"
    class="d-flex justify-content-between align-items-center border-top-0"
    :class="
      device == 'mobile'
        ? 'flex-column align-items-start justify-content-start'
        : ''
    "
  >
    <div
      class="left-pagination d-flex justify-ciontent-center align-items-center ps-4 py-3"
    >
      <!-- Left pagination -->
      <p
        class="p-0 m-0 fnt-regular fnt-caption text-black-500"
        v-if="datatotal >= 20"
      >
        {{
          $t("15", {
            first: calculateStartNo(),
            last: calculateEndNo(),
            total: total,
          })
        }}
        <!-- {{  calculateStartNo() }} - {{ calculateEndNo() }} of {{ total }} items -->
      </p>
      <Icon />
      <!-- <button class="pageCount p-0 border-0 m-0 ms-2">20/page <Icon icon="akar-icons:chevron-down" width="12" height="12" /></button> -->
      <div v-if="total > 20 && blnPerPage == true">
        <select
          id="perPage"
          class="ms-2 fnt-regular fnt-caption text-black-500"
          @change="perpageChange($event.target.value)"
        >
          <option value="20">{{ $t("16", { count: "20" }) }}</option>
          <option value="40">{{ $t("16", { count: "40" }) }}</option>
          <option value="100">{{ $t("16", { count: "100" }) }}</option>
        </select>
      </div>
    </div>
    <div
      v-if="showpagenumber()"
      class="right-pagination d-flex align-items-center justify-content-center"
    >
      <nav aria-label="Page navigation example " class="p-0 m-0">
        <ul class="pagination p-0 m-0 d-flex align-items-center">
          <li class="page-item me-2">
            <a
              class="page-link text-decoration-none text-black-600 rounded p-0 p-1 border-0 d-flex align-items-center justify-content-center"
              @click.prevent="onClickPreviousPage()"
              href="#"
              aria-label="Previous"
            >
              <span aria-hidden="true"
                ><Icon
                  icon="akar-icons:chevron-left"
                  :class="currentPage == 1 ? 'text-black-400' : ''"
                  width="24"
                  height="24"
              /></span>
            </a>
          </li>
          <li class="page-item mx-2" :key="page.id" v-for="page in pages">
            <a
              class="page-link p-0 p-2 rounded border-0 fnt-regular fnt-caption"
              :class="page.name == currentPage ? 'active' : ''"
              href="#"
              @click.prevent="onClickPage(page.name)"
              >{{ page.name }}</a
            >
          </li>
          <li class="page-item">
            <a
              class="page-link text-decoration-none text-black-600 rounded p-0 p-1 border-0 d-flex align-items-center justify-content-center"
              href="#"
              aria-label="Next"
              @click.prevent="onClicknNextPage()"
            >
              <span aria-hidden="true"
                ><Icon
                  icon="akar-icons:chevron-right"
                  :class="isInLastPage == true ? 'text-black-400' : ''"
                  width="24"
                  height="24"
              /></span>
            </a>
          </li>
        </ul>
      </nav>

      <div class="right d-flex align-items-center justify-content-end ms-2">
        <label for="goto" class="fnt-caption">{{ $t("17") }}</label>
        <input
          v-model="valueFgoto"
          type="string"
          name=""
          class="custom-form form-control ms-2 fnt-regular fnt-caption"
          id=""
          @keypress="IsNumber($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
//import Validation from "../../typescript/validator/validator";
export default {
  components: {
    Icon,
  },
  props: {
    pageination: { type: String },
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    datatotal: { type: String },
    total: { type: String },
    blnPerPage: { type: Boolean, default: true },
  },
  data() {
    return {
      currentPage: 1,
      intperpage: 20,
      valueFgoto: "1",
      numFgoto: 1,
    };
  },
  computed: {
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      if (this.totalPages === 0) {
        return true;
      }
      return this.currentPage === this.totalPages;
    },
    startPage() {
      if (this.currentPage === 1) {
        return 1;
      }
      if (this.totalPages < this.maxVisibleButtons) {
        return 1;
      }
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons + 1;
      }
      return this.currentPage - 1;
    },
    endPage() {
      if (this.totalPages === 0) {
        return 1;
      }
      if (this.totalPages < this.maxVisibleButtons) {
        return this.totalPages;
      }
      return Math.min(
        this.startPage + this.maxVisibleButtons - 1,
        this.totalPages
      );
    },
    pages() {
      const range = [];
      for (let i = this.startPage; i <= this.endPage; i++) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }
      return range;
    },
  },
  emits: ["pagechanged", "perpage"],
  methods: {
    calculateStartNo() {
      const intpage = this.currentPage - 1;
      const startNo = this.perPage * intpage;
      if (this.total == 0) {
        return startNo;
      } else {
        return startNo + 1;
      }
    },
    calculateEndNo() {
      if (this.perPage * this.currentPage < this.total) {
        return this.perPage * this.currentPage;
      } else if (this.perPage * this.currentPage > this.total) {
        return this.total;
      } else {
        return this.total;
      }
    },
    perpageChange(state) {
      this.intperpage = state;
      this.currentPage = state;
      this.numFgoto = state;
      this.valueFgoto = "1";

      this.$emit("perpage", this.intperpage);
    },

    showpagenumber() {
      if (this.totalPages > 1) {
        return true;
      } else {
        return false;
      }
    },
    //คำสั่งหน้าแรก
    onClickFirstPage() {
      //ถ้าหน้าปัจจุบันเป็นหน้าแรก คำสั่งจะไม่ทำงาน
      if (this.isInFirstPage) {
        return false;
      }
      this.currentPage = 1;
      this.$emit("pagechanged", 1);
    },
    //คำสั่งเปิดหน้าก่อน
    onClickPreviousPage() {
      //ถ้าหน้าปัจจุบันเป็นหน้าแรก คำสั่งจะไม่ทำงาน

      if (this.currentPage > 1) {
        this.currentPage = this.currentPage - 1;
        this.numFgoto = this.currentPage;
        this.valueFgoto = this.numFgoto;

        this.$emit("pagechanged", this.currentPage);
      } else {
        return false;
      }
    },

    IsNumber(event) {
      if (!/^[0-9]+$/.test(event.key)) {
        return event.preventDefault();
      }
      setTimeout(() => {
        this.numFgoto = parseInt(event.target.value);
        this.onClickPage(this.numFgoto);
      }, 1000);
    },

    //หน้าปัจจุบัน
    onClickPage(page) {
      setTimeout(() => {
        if (page > 0 && page <= this.totalPages) {
          this.currentPage = page;
          this.numFgoto = this.currentPage;
          this.valueFgoto = this.numFgoto;

          this.$emit("pagechanged", page);
        }
      }, 10);
    },
    //คำสั่งเปิดหน้าถัดไป
    onClicknNextPage() {
      //ถ้าหน้าปัจจุบันเป็นหน้าแรก คำสั่งจะทำงาน
      if (this.isInLastPage) {
        return true;
      }
      this.currentPage = this.currentPage + 1;
      this.numFgoto = this.currentPage;
      this.valueFgoto = this.numFgoto;

      this.$emit("pagechanged", this.currentPage);
    },
    //คำสั่งเปิดหน้าสุดท้าย
    onClickLastPage() {
      //ถ้าหน้าปัจจุบันเป็นหน้าสุดท้าย คำสั่งจะไม่ทำงาน
      if (this.isInLastPage) {
        return false;
      }
      this.currentPage = this.totalPages;
      this.$emit("pagechanged", this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
    renderTableDataOfAPI(page_no, data) {
      this.currentPage = page_no;
      return data;
    },
    renderTableData(page_no, data) {
      this.currentPage = page_no;
      var all_data = data;
      var resp;
      if (Array.isArray(all_data)) {
        resp = all_data.slice(
          this.intperpage * (page_no - 1),
          this.intperpage * page_no
        );
      } else {
        resp = []; // Initialize as an empty array if not an array
      }
      var SortData = resp;
      return SortData;
    },

    // renderTableData(page_no, data) {
    //   this.currentPage = page_no;
    //   var all_data = data;
    //   var resp;
    //   if (all_data !== undefined) {
    //     console.log(typeof all_data)
    //     resp = all_data?.slice(
    //       this.intperpage * (page_no - 1),
    //       this.intperpage * page_no
    //     );
    //   }
    //   var SortData = resp;
    //   return SortData;
    // },
  },
};
</script>

<style lang="scss" scoped>
.left-pagination {
  font-size: 12px;
  font-style: normal;
  // font-weight: lighter;
  .pageCount {
    font-style: normal;
    font-weight: lighter;
    &:hover {
      text-decoration: underline;
    }
  }
  select {
    border: none !important;
    // font-weight: lighter;
    outline: 0;
  }
}
.page-link {
  width: 31px;
  text-align: center;
  font-size: 12px;
  color: #000;
  &:focus {
    box-shadow: none !important;
  }
  &:hover {
    background-color: $black-100;
    color: $primary;
  }
  &:active {
    background-color: $black-300;
    color: $primary;
  }
}
.custom-form {
  width: 50px;
  height: 28px;
  &:focus {
    box-shadow: none !important;
  }
}
.right-pagination {
  .right {
    label {
      font-size: 12px;
      // font-weight: lighter;
    }
  }
}
.page-link-dot {
  position: relative;
  display: block;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.active {
  background-color: $primary-600 !important;
  color: $white !important;
}
</style>
